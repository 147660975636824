<template>
  <div class="wrapper">
    <TreeToVliew :isDeep="false" @request="request"></TreeToVliew>
    <right-content>
      <div class="right-content_head">
        <el-button @click="exportSentry">导出监督岗</el-button>
      </div>
      <div class="right-content_table">
        <span class="title">{{ departmentName }}:监督岗{{ total }}个</span>
        <div class="table">
          <my-table
            @currentPage="currentPage"
            :tableInfo="tableInfo"
            :colConfigs="colConfigs"
          >
            <el-table-column
              width="300px"
              align="center"
              slot="option"
              label="操作"
            >
              <template slot-scope="{ row }">
                <div class="option">
                  <el-button
                    icon="iconfont icon-chakan-copy1"
                    @click="check(row)"
                    >查看</el-button
                  >
                </div>
              </template>
            </el-table-column>
          </my-table>
        </div>
      </div>
    </right-content>
  </div>
</template>

<script>
import MyTable from "../../components/MyTable";
import { getSentryList,exportSentry } from "@/api";
import TreeToVliew from "@/views/commonViews/TreeToVliew";
import RightContent from "../commonViews/RightContent";
import { exportFun } from "@/utils/params";
export default {
  name: "supervision",
  components: { RightContent, MyTable, TreeToVliew },
  watch:{
    page(curent){
      this.request({
        ...this.requestParam,
        page:curent
      })
    }
  },
  methods: {
    exportSentry(){
      const {page,...params} = this.requestParam;
      const param = {
        type: 2,
        ...params,
        group_id:localStorage.getItem("group_id"),
      }
      exportFun(exportSentry,param,"exportSentry")
    },
    currentPage(current) {
      this.page = current;
    },
    async getSentrysData(data) {
      const res = await getSentryList(data);
      if (res.status === 200) {
        this.tableInfo = res.data.data;
        this.total = res.data.data.total;
      }
    },
    request(request) {
      const {label,...params} = request;
      this.requestParam = params;
      this.departmentName = label;
      this.getSentrysData(params);
    },
    check(row) {
      this.$router.push({
        name: "supervision-detail",
        query: {
          info: JSON.stringify(row),
        },
      });
    },
  },
  data() {
    return {
      tableInfo: {},
      //每次请求的对应的不同的部门总包部
      requestParam:{},
      departmentName:"",
      page: 1,
      total:0,
      colConfigs: [
        { prop: "name", label: "监督岗名称" },
        { prop: "people_name", label: "岗长" },
        { prop: "people_number", label: "联系电话" },
        { slot: "option" },
      ]
    };
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  height: 100%;
  display: flex;
  .right-content_head {
    margin-bottom: 20px;
    button {
      background-color: red;
      color: #fff;
      padding: 10px 25px;
    }
  }
  .right-content_table {
    .table {
      margin: 20px 0;
    }
    button {
      padding: 7px 15px;
      background: #fde5e4;
      border-radius: 4px;
      border: 0;
      font-size: 15px;
      font-family: PingFang SC;
      color: #e32322;
    }
  }
}
</style>
